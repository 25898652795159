/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OverrideCustomDate_jsonld_override_read } from './OverrideCustomDate_jsonld_override_read';
import type { OverrideMonthDays_jsonld_override_read } from './OverrideMonthDays_jsonld_override_read';
import type { OverrideWeekDay_jsonld_override_read } from './OverrideWeekDay_jsonld_override_read';
import type { OverrideYearDates_jsonld_override_read } from './OverrideYearDates_jsonld_override_read';

export type Override_jsonld_override_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Override_jsonld_override_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    boats?: Array<string>;
    startAt?: string | null;
    endAt?: string | null;
    yearDates?: OverrideYearDates_jsonld_override_read;
    monthDays?: OverrideMonthDays_jsonld_override_read;
    weekDays?: Array<OverrideWeekDay_jsonld_override_read>;
    customDates?: Array<OverrideCustomDate_jsonld_override_read>;
    timeFrom?: string | null;
    timeTo?: string | null;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    readonly frequency?: Override_jsonld_override_read.frequency;
};

export namespace Override_jsonld_override_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum frequency {
        YEARLY = 'yearly',
        MONTHLY = 'monthly',
        WEEKLY = 'weekly',
        CUSTOM_DATES = 'custom_dates',
    }


}

