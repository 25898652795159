/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IncomeStatisticQuery_statistics_write = {
    dateFrom: string | null;
    dateTo: string | null;
    /**
     * Graph Date Group (days/weeks/months/years/whole).
     */
    dateGroup: IncomeStatisticQuery_statistics_write.dateGroup | null;
    includeCancellationFee: boolean | null;
    includeAddons: boolean | null;
};

export namespace IncomeStatisticQuery_statistics_write {

    /**
     * Graph Date Group (days/weeks/months/years/whole).
     */
    export enum dateGroup {
        DAYS = 'days',
        WEEKS = 'weeks',
        MONTHS = 'months',
        YEARS = 'years',
        WHOLE = 'whole',
    }


}

