/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Statistic_StatisticSummary_jsonld_statistics_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Statistic_StatisticSummary_jsonld_statistics_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    totalIncome?: number;
    reservations?: number;
    privateCruisesIncome?: number;
    publicCruisesIncome?: number;
    privateReservations?: number;
    publicReservations?: number;
};

export namespace Statistic_StatisticSummary_jsonld_statistics_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

