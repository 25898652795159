import {toast} from 'react-toastify'
import {ToastOptions} from 'react-toastify/dist/types'

export default function notification (type: string, message: string) {
  const option: ToastOptions = {
    style: {
      whiteSpace: 'pre'
    }
  }
  switch (type) {
    case 'error':
      toast.error(message, option)
      break
    case 'info':
      toast.info(message, option)
      break
    case 'warning':
      toast.warning(message, option)
      break
    case 'success':
      toast.success(message, option)
      break
    default:
      toast(message)
      break
  }
}