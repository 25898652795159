/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Timetable_jsonld_boat_group_read } from './Timetable_jsonld_boat_group_read';

export type Schedule_jsonld_boat_group_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Schedule_jsonld_boat_group_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    monthFrom?: number;
    dayFrom?: number;
    monthTo?: number;
    dayTo?: number;
    timetables?: Array<Timetable_jsonld_boat_group_read>;
};

export namespace Schedule_jsonld_boat_group_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

