/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Discount_promotion_read } from './Discount_promotion_read';

export type PromotionTypeSettings_promotion_read = {
    discount?: Discount_promotion_read;
    readonly usage?: PromotionTypeSettings_promotion_read.usage;
};

export namespace PromotionTypeSettings_promotion_read {

    export enum usage {
        SINGLE = 'single',
        MULTIPLE = 'multiple',
    }


}

