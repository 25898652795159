/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_jsonld_reservation_read } from './Addon_jsonld_reservation_read';
import type { ReservationTaxReceipt_jsonld_reservation_read } from './ReservationTaxReceipt_jsonld_reservation_read';

export type ReservationAddonReceipt_jsonld_reservation_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ReservationAddonReceipt_jsonld_reservation_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    addon?: Addon_jsonld_reservation_read;
    readonly addonPrice?: number;
    readonly subtotal?: number;
    taxes?: Array<ReservationTaxReceipt_jsonld_reservation_read>;
    readonly taxesTotal?: number;
    readonly total?: number;
    readonly addonPriceType?: ReservationAddonReceipt_jsonld_reservation_read.addonPriceType;
};

export namespace ReservationAddonReceipt_jsonld_reservation_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum addonPriceType {
        FIXED = 'fixed',
        HOURLY = 'hourly',
    }


}

