/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BoatPass_boat_pass_read } from '../models/BoatPass_boat_pass_read';
import type { BoatPass_BoatPassDto_boat_pass_write } from '../models/BoatPass_BoatPassDto_boat_pass_write';
import type { BoatPass_BoatPassReceipt_boat_pass_receipt_read } from '../models/BoatPass_BoatPassReceipt_boat_pass_receipt_read';
import type { BoatPass_BoatPassReceiptDto_boat_pass_receipt_write } from '../models/BoatPass_BoatPassReceiptDto_boat_pass_receipt_write';
import type { BoatPass_BoatPassRemainingCruisesDto_boat_pass_write } from '../models/BoatPass_BoatPassRemainingCruisesDto_boat_pass_write';
import type { BoatPass_CreatedBoatPassOutput_boat_pass_read } from '../models/BoatPass_CreatedBoatPassOutput_boat_pass_read';
import type { BoatPass_EmptyDto_boat_pass_write } from '../models/BoatPass_EmptyDto_boat_pass_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BoatPassService {

    /**
     * Retrieves the collection of BoatPass resources.
     * Retrieves the collection of BoatPass resources.
     * @returns BoatPass_boat_pass_read BoatPass collection
     * @throws ApiError
     */
    public static apiAdminboatPassesGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<BoatPass_boat_pass_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boat-passes',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Retrieves a BoatPass resource.
     * Retrieves a BoatPass resource.
     * @returns BoatPass_boat_pass_read BoatPass resource
     * @throws ApiError
     */
    public static apiAdminboatPassesIdGet({
        id,
    }: {
        /**
         * BoatPass identifier
         */
        id: string,
    }): CancelablePromise<BoatPass_boat_pass_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boat-passes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the BoatPass resource.
     * Replaces the BoatPass resource.
     * @returns BoatPass_boat_pass_read BoatPass resource updated
     * @throws ApiError
     */
    public static apiAdminboatPassesIdremainingCruisesPut({
        id,
        requestBody,
    }: {
        /**
         * BoatPass identifier
         */
        id: string,
        /**
         * The updated BoatPass resource
         */
        requestBody: BoatPass_BoatPassRemainingCruisesDto_boat_pass_write,
    }): CancelablePromise<BoatPass_boat_pass_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/boat-passes/{id}/remaining-cruises',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a BoatPass resource.
     * Creates a BoatPass resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminboatPassesIdresendReceiptEmailPost({
        id,
        requestBody,
    }: {
        /**
         * BoatPass identifier
         */
        id: string,
        /**
         * The new BoatPass resource
         */
        requestBody: BoatPass_EmptyDto_boat_pass_write,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/boat-passes/{id}/resend-receipt-email',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a BoatPass resource.
     * Creates a BoatPass resource.
     * @returns BoatPass_BoatPassReceipt_boat_pass_receipt_read BoatPass resource created
     * @throws ApiError
     */
    public static apiClientboatPassReceiptsPost({
        requestBody,
    }: {
        /**
         * The new BoatPass resource
         */
        requestBody: BoatPass_BoatPassReceiptDto_boat_pass_receipt_write,
    }): CancelablePromise<BoatPass_BoatPassReceipt_boat_pass_receipt_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client/boat-pass-receipts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a BoatPass resource.
     * Creates a BoatPass resource.
     * @returns BoatPass_CreatedBoatPassOutput_boat_pass_read BoatPass resource created
     * @throws ApiError
     */
    public static apiClientboatPassesPost({
        requestBody,
    }: {
        /**
         * The new BoatPass resource
         */
        requestBody: BoatPass_BoatPassDto_boat_pass_write,
    }): CancelablePromise<BoatPass_CreatedBoatPassOutput_boat_pass_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client/boat-passes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a BoatPass resource.
     * Retrieves a BoatPass resource.
     * @returns void
     * @throws ApiError
     */
    public static apiClientboatPassesIdGet({
        id,
    }: {
        /**
         * BoatPass identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/boat-passes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
