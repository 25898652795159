/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BlockFieldDto_jsonld_block_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BlockFieldDto_jsonld_block_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    key: string | null;
    text?: string | null;
    html?: string | null;
    /**
     * Media Object.
     */
    image?: string | null;
    /**
     * Media Object.
     */
    images?: Array<string>;
};

export namespace BlockFieldDto_jsonld_block_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

