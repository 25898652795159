/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BoatGroup_jsonld_promotion_write } from './BoatGroup_jsonld_promotion_write';
import type { PromotionTypeDto_jsonld_promotion_write } from './PromotionTypeDto_jsonld_promotion_write';
import type { TimeRestrictionDto_jsonld_promotion_write } from './TimeRestrictionDto_jsonld_promotion_write';
import type { VoucherTypeDto_jsonld_promotion_write } from './VoucherTypeDto_jsonld_promotion_write';

export type Promotion_PromotionDto_jsonld_promotion_write = {
    name: string | null;
    description: string | null;
    isActive: boolean | null;
    validFrom: string | null;
    validTo: string | null;
    type: Promotion_PromotionDto_jsonld_promotion_write.type | null;
    promotionType?: PromotionTypeDto_jsonld_promotion_write | null;
    voucherType?: VoucherTypeDto_jsonld_promotion_write | null;
    isBookingFeeFree: boolean | null;
    isTipOptional: boolean | null;
    codes: Array<string>;
    purchaseRestriction: TimeRestrictionDto_jsonld_promotion_write | null;
    cruiseRestriction: TimeRestrictionDto_jsonld_promotion_write | null;
    minReservationTime?: number | null;
    maxReservationTime?: number | null;
    cruiseType: Promotion_PromotionDto_jsonld_promotion_write.cruiseType | null;
    boatGroups: Array<BoatGroup_jsonld_promotion_write>;
};

export namespace Promotion_PromotionDto_jsonld_promotion_write {

    export enum type {
        PROMOTION = 'promotion',
        VOUCHER = 'voucher',
    }

    export enum cruiseType {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

