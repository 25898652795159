/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User_PermissionOutput } from '../models/User_PermissionOutput';
import type { User_user_read } from '../models/User_user_read';
import type { User_UserDto_user_write } from '../models/User_UserDto_user_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Retrieves a User resource.
     * Retrieves a User resource.
     * @returns User_user_read User resource
     * @throws ApiError
     */
    public static apiAdminmeGet(): CancelablePromise<User_user_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/me',
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of User resources.
     * Retrieves the collection of User resources.
     * @returns User_PermissionOutput User collection
     * @throws ApiError
     */
    public static apiAdminuserPermissionsGetCollection(): CancelablePromise<Array<User_PermissionOutput>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/user-permissions',
        });
    }

    /**
     * Retrieves the collection of User resources.
     * Retrieves the collection of User resources.
     * @returns User_user_read User collection
     * @throws ApiError
     */
    public static apiAdminusersGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<User_user_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/users',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a User resource.
     * Creates a User resource.
     * @returns User_user_read User resource created
     * @throws ApiError
     */
    public static apiAdminusersPost({
        requestBody,
    }: {
        /**
         * The new User resource
         */
        requestBody: User_UserDto_user_write,
    }): CancelablePromise<User_user_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a User resource.
     * Retrieves a User resource.
     * @returns User_user_read User resource
     * @throws ApiError
     */
    public static apiAdminusersIdGet({
        id,
    }: {
        /**
         * User identifier
         */
        id: string,
    }): CancelablePromise<User_user_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the User resource.
     * Replaces the User resource.
     * @returns User_user_read User resource updated
     * @throws ApiError
     */
    public static apiAdminusersIdPut({
        id,
        requestBody,
    }: {
        /**
         * User identifier
         */
        id: string,
        /**
         * The updated User resource
         */
        requestBody: User_UserDto_user_write,
    }): CancelablePromise<User_user_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the User resource.
     * Removes the User resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminusersIdDelete({
        id,
    }: {
        /**
         * User identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
