/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PromotionCode_jsonld_promotion_read } from './PromotionCode_jsonld_promotion_read';
import type { PromotionTypeSettings_jsonld_promotion_read } from './PromotionTypeSettings_jsonld_promotion_read';
import type { TimeRestriction_jsonld_promotion_read } from './TimeRestriction_jsonld_promotion_read';
import type { VoucherTypeSettings_jsonld_promotion_read } from './VoucherTypeSettings_jsonld_promotion_read';

export type Promotion_jsonld_promotion_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Promotion_jsonld_promotion_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    description?: string;
    isActive?: boolean;
    validFrom?: string;
    validTo?: string;
    promotionType?: PromotionTypeSettings_jsonld_promotion_read | null;
    voucherType?: VoucherTypeSettings_jsonld_promotion_read | null;
    isBookingFeeFree?: boolean;
    isTipOptional?: boolean;
    codes?: Array<PromotionCode_jsonld_promotion_read>;
    purchaseRestriction?: TimeRestriction_jsonld_promotion_read;
    cruiseRestriction?: TimeRestriction_jsonld_promotion_read;
    minReservationTime?: number | null;
    maxReservationTime?: number | null;
    boatGroups?: Array<string>;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    deletedAt?: string | null;
    usage?: string | null;
    readonly type?: Promotion_jsonld_promotion_read.type;
    readonly cruiseType?: string;
};

export namespace Promotion_jsonld_promotion_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum type {
        PROMOTION = 'promotion',
        VOUCHER = 'voucher',
    }


}

