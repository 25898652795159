/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Report_BoatGroupReportItem_boat_groups_report_read } from '../models/Report_BoatGroupReportItem_boat_groups_report_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportService {

    /**
     * Retrieves the collection of Report resources.
     * Retrieves the collection of Report resources.
     * @returns Report_BoatGroupReportItem_boat_groups_report_read Report collection
     * @throws ApiError
     */
    public static apiAdminreportsboatGroupsGetCollection({
        json,
    }: {
        /**
         * Boat Groups Report Query Json
         */
        json: string,
    }): CancelablePromise<Array<Report_BoatGroupReportItem_boat_groups_report_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/boat-groups',
            query: {
                'json': json,
            },
        });
    }

    /**
     * Retrieves a Report resource.
     * Retrieves a Report resource.
     * @returns binary Report Excel File
     * @throws ApiError
     */
    public static apiAdminreportsboatGroupsexcelGet({
        json,
    }: {
        /**
         * Boat Groups Report Query Json
         */
        json: string,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/boat-groups/excel',
            headers: {
              accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            query: {
                'json': json,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves a Report resource.
     * Retrieves a Report resource.
     * @returns binary Detailed Report Excel File
     * @throws ApiError
     */
    public static apiAdminreportsboatGroupsIdTypeexcelGet({
        id,
        type,
        json,
    }: {
        /**
         * Report identifier
         */
        id: string,
        /**
         * Report identifier
         */
        type: string,
        /**
         * Boat Groups Report Query Json
         */
        json: string,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reports/boat-groups/{id}/{type}/excel',
            headers: {
              accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            path: {
                'id': id,
                'type': type,
            },
            query: {
                'json': json,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
