/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Settings_settings_read } from '../models/Settings_settings_read';
import type { Settings_SettingsDto } from '../models/Settings_SettingsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * Retrieves a Settings resource.
     * Retrieves a Settings resource.
     * @returns Settings_settings_read Settings resource
     * @throws ApiError
     */
    public static apiAdminsettingsGet(): CancelablePromise<Settings_settings_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/settings',
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Updates the Settings resource.
     * Updates the Settings resource.
     * @returns Settings_settings_read Settings resource updated
     * @throws ApiError
     */
    public static apiAdminsettingsPatch({
        requestBody,
    }: {
        /**
         * The updated Settings resource
         */
        requestBody: Settings_SettingsDto,
    }): CancelablePromise<Settings_settings_read> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/admin/settings',
            body: requestBody,
            mediaType: 'application/merge-patch+json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Settings resource.
     * Retrieves a Settings resource.
     * @returns Settings_settings_read Settings resource
     * @throws ApiError
     */
    public static apiClientsettingsGet(): CancelablePromise<Settings_settings_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/settings',
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
