/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HowDidYouHear } from '../models/HowDidYouHear';
import type { Occasion } from '../models/Occasion';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SurveyService {

    /**
     * Retrieves the collection of HowDidYouHear resources.
     * Retrieves the collection of HowDidYouHear resources.
     * @returns HowDidYouHear HowDidYouHear collection
     * @throws ApiError
     */
    public static apiHowDidYouHearsGetCollection(): CancelablePromise<Array<HowDidYouHear>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/how-did-you-hears',
        });
    }

    /**
     * Retrieves a HowDidYouHear resource.
     * Retrieves a HowDidYouHear resource.
     * @returns HowDidYouHear HowDidYouHear resource
     * @throws ApiError
     */
    public static apiHowDidYouHearsIdGet({
        id,
    }: {
        /**
         * HowDidYouHear identifier
         */
        id: string,
    }): CancelablePromise<HowDidYouHear> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/how-did-you-hears/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of Occasion resources.
     * Retrieves the collection of Occasion resources.
     * @returns Occasion Occasion collection
     * @throws ApiError
     */
    public static apiOccasionsGetCollection(): CancelablePromise<Array<Occasion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/occasions',
        });
    }

    /**
     * Retrieves a Occasion resource.
     * Retrieves a Occasion resource.
     * @returns Occasion Occasion resource
     * @throws ApiError
     */
    public static apiOccasionsIdGet({
        id,
    }: {
        /**
         * Occasion identifier
         */
        id: string,
    }): CancelablePromise<Occasion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/occasions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
