/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_cruise_read } from './Addon_cruise_read';
import type { BoatPass_cruise_read } from './BoatPass_cruise_read';
import type { Insurance_cruise_read } from './Insurance_cruise_read';
import type { Payment_cruise_read } from './Payment_cruise_read';
import type { ReservationAddress_cruise_read } from './ReservationAddress_cruise_read';
import type { ReservationPromocode_cruise_read } from './ReservationPromocode_cruise_read';
import type { ReservationReceipt_cruise_read } from './ReservationReceipt_cruise_read';
import type { SecurityDeposit_cruise_read } from './SecurityDeposit_cruise_read';

export type Reservation_cruise_read = {
    readonly id?: number;
    seats?: number | null;
    addons?: Array<Addon_cruise_read>;
    hasCancellation?: boolean;
    insurance?: Insurance_cruise_read | null;
    promocode?: ReservationPromocode_cruise_read | null;
    boatPass?: BoatPass_cruise_read | null;
    receipt?: ReservationReceipt_cruise_read;
    customerAddress?: ReservationAddress_cruise_read;
    notes?: string | null;
    isSuperoverride?: boolean;
    createdAt?: string;
    readonly updatedAt?: string | null;
    securityDeposit?: SecurityDeposit_cruise_read | null;
    readonly isWalkIn?: boolean;
    hasEmailNewsletter?: boolean;
    readonly status?: Reservation_cruise_read.status;
    readonly creationSide?: string;
    readonly lastPayment?: Payment_cruise_read | null;
    readonly canEdit?: boolean;
    readonly canCancel?: boolean;
};

export namespace Reservation_cruise_read {

    export enum status {
        PROCESSING = 'processing',
        PAID = 'paid',
        CANCELED = 'canceled',
        FAILED = 'failed',
    }


}

