/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailTemplate_email_template_read } from '../models/EmailTemplate_email_template_read';
import type { EmailTemplate_EmailTemplateDto } from '../models/EmailTemplate_EmailTemplateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmailTemplateService {

    /**
     * Retrieves the collection of EmailTemplate resources.
     * Retrieves the collection of EmailTemplate resources.
     * @returns EmailTemplate_email_template_read EmailTemplate collection
     * @throws ApiError
     */
    public static apiAdminemailTemplatesGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<EmailTemplate_email_template_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/email-templates',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Retrieves a EmailTemplate resource.
     * Retrieves a EmailTemplate resource.
     * @returns EmailTemplate_email_template_read EmailTemplate resource
     * @throws ApiError
     */
    public static apiAdminemailTemplatesIdGet({
        id,
    }: {
        /**
         * EmailTemplate identifier
         */
        id: string,
    }): CancelablePromise<EmailTemplate_email_template_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/email-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the EmailTemplate resource.
     * Replaces the EmailTemplate resource.
     * @returns EmailTemplate_email_template_read EmailTemplate resource updated
     * @throws ApiError
     */
    public static apiAdminemailTemplatesIdPut({
        id,
        requestBody,
    }: {
        /**
         * EmailTemplate identifier
         */
        id: string,
        /**
         * The updated EmailTemplate resource
         */
        requestBody: EmailTemplate_EmailTemplateDto,
    }): CancelablePromise<EmailTemplate_email_template_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/email-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

}
