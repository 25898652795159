/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Discount_jsonld_promotion_read } from './Discount_jsonld_promotion_read';

export type PromotionTypeSettings_jsonld_promotion_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: PromotionTypeSettings_jsonld_promotion_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    discount?: Discount_jsonld_promotion_read;
    readonly usage?: PromotionTypeSettings_jsonld_promotion_read.usage;
};

export namespace PromotionTypeSettings_jsonld_promotion_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum usage {
        SINGLE = 'single',
        MULTIPLE = 'multiple',
    }


}

