/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Promotion_promotion_read } from '../models/Promotion_promotion_read';
import type { Promotion_PromotionDto_promotion_write } from '../models/Promotion_PromotionDto_promotion_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromotionService {

    /**
     * Retrieves the collection of Promotion resources.
     * Retrieves the collection of Promotion resources.
     * @returns Promotion_promotion_read Promotion collection
     * @throws ApiError
     */
    public static apiAdminpromotionsGetCollection({
        page = 1,
        name,
        type,
        promotionTypeUsage,
        isActive,
        codesCode,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        name?: string,
        type?: Array<string>,
        promotionTypeUsage?: Array<string>,
        isActive?: Array<boolean>,
        codesCode?: string,
    }): CancelablePromise<Array<Promotion_promotion_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/promotions',
            query: {
                'page': page,
                'name': name,
                'type[]': type,
                'promotionType.usage[]': promotionTypeUsage,
                'isActive[]': isActive,
                'codes.code': codesCode,
            },
        });
    }

    /**
     * Creates a Promotion resource.
     * Creates a Promotion resource.
     * @returns Promotion_promotion_read Promotion resource created
     * @throws ApiError
     */
    public static apiAdminpromotionsPost({
        requestBody,
    }: {
        /**
         * The new Promotion resource
         */
        requestBody: Promotion_PromotionDto_promotion_write,
    }): CancelablePromise<Promotion_promotion_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/promotions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Promotion resource.
     * Retrieves a Promotion resource.
     * @returns Promotion_promotion_read Promotion resource
     * @throws ApiError
     */
    public static apiAdminpromotionsIdGet({
        id,
    }: {
        /**
         * Promotion identifier
         */
        id: string,
    }): CancelablePromise<Promotion_promotion_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/promotions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Promotion resource.
     * Replaces the Promotion resource.
     * @returns Promotion_promotion_read Promotion resource updated
     * @throws ApiError
     */
    public static apiAdminpromotionsIdPut({
        id,
        requestBody,
    }: {
        /**
         * Promotion identifier
         */
        id: string,
        /**
         * The updated Promotion resource
         */
        requestBody: Promotion_PromotionDto_promotion_write,
    }): CancelablePromise<Promotion_promotion_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/promotions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the Promotion resource.
     * Removes the Promotion resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminpromotionsIdDelete({
        id,
    }: {
        /**
         * Promotion identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/promotions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves a Promotion resource.
     * Retrieves a Promotion resource.
     * @returns void
     * @throws ApiError
     */
    public static apiClientpromotionsIdGet({
        id,
    }: {
        /**
         * Promotion identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/promotions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
