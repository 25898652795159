/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_jsonld_reservation_read } from './Addon_jsonld_reservation_read';
import type { BoatPass_jsonld_reservation_read } from './BoatPass_jsonld_reservation_read';
import type { Cruise_jsonld_reservation_read } from './Cruise_jsonld_reservation_read';
import type { Insurance_jsonld_reservation_read } from './Insurance_jsonld_reservation_read';
import type { Payment_jsonld_reservation_read } from './Payment_jsonld_reservation_read';
import type { ReservationAddress_jsonld_reservation_read } from './ReservationAddress_jsonld_reservation_read';
import type { ReservationPromocode_jsonld_reservation_read } from './ReservationPromocode_jsonld_reservation_read';
import type { ReservationReceipt_jsonld_reservation_read } from './ReservationReceipt_jsonld_reservation_read';
import type { ReservationSurvey_jsonld_reservation_read } from './ReservationSurvey_jsonld_reservation_read';
import type { SecurityDeposit_jsonld_reservation_read } from './SecurityDeposit_jsonld_reservation_read';

export type Reservation_jsonld_reservation_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Reservation_jsonld_reservation_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    cruise?: Cruise_jsonld_reservation_read;
    seats?: number | null;
    addons?: Array<Addon_jsonld_reservation_read>;
    hasCancellation?: boolean;
    insurance?: Insurance_jsonld_reservation_read | null;
    promocode?: ReservationPromocode_jsonld_reservation_read | null;
    boatPass?: BoatPass_jsonld_reservation_read | null;
    receipt?: ReservationReceipt_jsonld_reservation_read;
    survey?: ReservationSurvey_jsonld_reservation_read;
    customerAddress?: ReservationAddress_jsonld_reservation_read;
    billingAddress?: ReservationAddress_jsonld_reservation_read | null;
    notes?: string | null;
    isSuperoverride?: boolean;
    createdAt?: string;
    readonly updatedAt?: string | null;
    payments?: Array<Payment_jsonld_reservation_read>;
    securityDeposit?: SecurityDeposit_jsonld_reservation_read | null;
    readonly isWalkIn?: boolean;
    hasEmailNewsletter?: boolean;
    readonly status?: Reservation_jsonld_reservation_read.status;
    readonly creationSide?: string;
    readonly lastPayment?: Payment_jsonld_reservation_read | null;
    readonly canEdit?: boolean;
    readonly canCancel?: boolean;
};

export namespace Reservation_jsonld_reservation_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum status {
        PROCESSING = 'processing',
        PAID = 'paid',
        CANCELED = 'canceled',
        FAILED = 'failed',
    }


}

