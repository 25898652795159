export const PERMISSION_SUPER_ADMIN = 'PERMISSION_SUPER_ADMIN'

export const PERMISSION_DASHBOARD = 'PERMISSION_DASHBOARD'
export const PERMISSION_RESERVATION = 'PERMISSION_RESERVATION'
export const PERMISSION_RESERVATION_MANAGE = 'PERMISSION_RESERVATION_MANAGE'
export const PERMISSION_RESERVATION_VIEW_RECEIPT = 'PERMISSION_RESERVATION_VIEW_RECEIPT'
export const PERMISSION_BOAT_MANAGEMENT_BOATS = 'PERMISSION_BOAT_MANAGEMENT_BOATS'
export const PERMISSION_BOAT_MANAGEMENT_GROUPS = 'PERMISSION_BOAT_MANAGEMENT_GROUPS'
export const PERMISSION_BOAT_MANAGEMENT_LOCATIONS = 'PERMISSION_BOAT_MANAGEMENT_LOCATIONS'
export const PERMISSION_PROMOTIONS = 'PERMISSION_PROMOTIONS'
export const PERMISSION_VOUCHERS = 'PERMISSION_VOUCHERS'
export const PERMISSION_ADDONS = 'PERMISSION_ADDONS'
export const PERMISSION_TAXES = 'PERMISSION_TAXES'
export const PERMISSION_BLOCKS = 'PERMISSION_BLOCKS'
export const PERMISSION_SETTINGS = 'PERMISSION_SETTINGS'
export const PERMISSION_PAYMENTS = 'PERMISSION_PAYMENTS'
export const PERMISSION_OVERRIDES = 'PERMISSION_OVERRIDES'
export const PERMISSION_EMAILS = 'PERMISSION_EMAILS'
export const PERMISSION_SMS = 'PERMISSION_SMS'
export const PERMISSION_USERS = 'PERMISSION_USERS'
export const PERMISSION_LOGS = 'PERMISSION_LOGS'

export const PERMISSION_BOAT_PASSES = 'PERMISSION_BOAT_PASSES'
export const PERMISSION_QR_CODES = 'PERMISSION_QR_CODES'
export const PERMISSION_REPORTS = 'PERMISSION_REPORTS'

export const PERMISSIONS = [
  PERMISSION_SUPER_ADMIN,
  PERMISSION_DASHBOARD,
  PERMISSION_RESERVATION,
  PERMISSION_RESERVATION_MANAGE,
  PERMISSION_RESERVATION_VIEW_RECEIPT,
  PERMISSION_BOAT_MANAGEMENT_BOATS,
  PERMISSION_BOAT_MANAGEMENT_GROUPS,
  PERMISSION_BOAT_MANAGEMENT_LOCATIONS,
  PERMISSION_PROMOTIONS,
  PERMISSION_VOUCHERS,
  PERMISSION_ADDONS,
  PERMISSION_TAXES,
  PERMISSION_BLOCKS,
  PERMISSION_SETTINGS,
  PERMISSION_PAYMENTS,
  PERMISSION_OVERRIDES,
  PERMISSION_EMAILS,
  PERMISSION_SMS,
  PERMISSION_USERS,
  PERMISSION_LOGS,
  PERMISSION_BOAT_PASSES,
  PERMISSION_QR_CODES,
  PERMISSION_REPORTS,
]
