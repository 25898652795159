/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TimetableDto_jsonld_location_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: TimetableDto_jsonld_location_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    /**
     * The day of the week: 1 (for Monday) through 7 (for Sunday).
     */
    day: number | null;
    openingTime: string | null;
    closingTime: string | null;
};

export namespace TimetableDto_jsonld_location_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

