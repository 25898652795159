/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Payment_payment_read } from '../models/Payment_payment_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * Retrieves the collection of Payment resources.
     * Retrieves the collection of Payment resources.
     * @returns Payment_payment_read Payment collection
     * @throws ApiError
     */
    public static apiAdminpaymentsGetCollection({
        page = 1,
        pagination,
        productId,
        productType,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * Enable or disable pagination
         */
        pagination?: boolean,
        productId?: Array<string>,
        productType?: Array<string>,
    }): CancelablePromise<Array<Payment_payment_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/payments',
            query: {
                'page': page,
                'pagination': pagination,
                'productId[]': productId,
                'productType[]': productType,
            },
        });
    }

    /**
     * Retrieves a Payment resource.
     * Retrieves a Payment resource.
     * @returns Payment_payment_read Payment resource
     * @throws ApiError
     */
    public static apiAdminpaymentsIdGet({
        id,
    }: {
        /**
         * Payment identifier
         */
        id: string,
    }): CancelablePromise<Payment_payment_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/payments/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
