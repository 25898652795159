/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Tax_jsonld_addon_write } from './Tax_jsonld_addon_write';

export type Addon_AddonDto_jsonld_addon_write = {
    name: string | null;
    description: string | null;
    price: number | null;
    priceType: Addon_AddonDto_jsonld_addon_write.priceType | null;
    taxes?: Array<Tax_jsonld_addon_write>;
    /**
     * Minimum Reservation Time (in minutes).
     */
    minReservationTime?: number | null;
    /**
     * Maximum Reservation Time (in minutes).
     */
    maxReservationTime?: number | null;
    /**
     * Pre Booking Time (in minutes).
     */
    preBookingTime?: number | null;
};

export namespace Addon_AddonDto_jsonld_addon_write {

    export enum priceType {
        FIXED = 'fixed',
        HOURLY = 'hourly',
    }


}

