/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MonthDaysDto_override_boat_write } from './MonthDaysDto_override_boat_write';
import type { YearDatesDto_override_boat_write } from './YearDatesDto_override_boat_write';

export type OverrideBoatQuery_override_boat_write = {
    frequency: OverrideBoatQuery_override_boat_write.frequency | null;
    startAt?: string | null;
    endAt?: string | null;
    yearDates?: YearDatesDto_override_boat_write | null;
    monthDays?: MonthDaysDto_override_boat_write | null;
    weekDays?: Array<number>;
    customDates?: Array<string>;
    timeFrom?: string | null;
    timeTo?: string | null;
};

export namespace OverrideBoatQuery_override_boat_write {

    export enum frequency {
        YEARLY = 'yearly',
        MONTHLY = 'monthly',
        WEEKLY = 'weekly',
    }


}

