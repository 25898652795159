/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BoatGroupContent_jsonld_client_boat_group_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatGroupContent_jsonld_client_boat_group_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    shortDescription?: string | null;
    list1?: string | null;
    list2?: string | null;
    additionalInformation?: string | null;
};

export namespace BoatGroupContent_jsonld_client_boat_group_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

