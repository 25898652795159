/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BoatPassType_jsonld_reservation_boat_pass_validation_read } from './BoatPassType_jsonld_reservation_boat_pass_validation_read';

export type Reservation_ReservationBoatPassValidation_jsonld_reservation_boat_pass_validation_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Reservation_ReservationBoatPassValidation_jsonld_reservation_boat_pass_validation_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    isValid?: boolean;
    error?: string | null;
    boatPassType?: BoatPassType_jsonld_reservation_boat_pass_validation_read;
};

export namespace Reservation_ReservationBoatPassValidation_jsonld_reservation_boat_pass_validation_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

