/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SmsTemplate_sms_template_read } from '../models/SmsTemplate_sms_template_read';
import type { SmsTemplate_SmsTemplateDto } from '../models/SmsTemplate_SmsTemplateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SmsTemplateService {

    /**
     * Retrieves the collection of SmsTemplate resources.
     * Retrieves the collection of SmsTemplate resources.
     * @returns SmsTemplate_sms_template_read SmsTemplate collection
     * @throws ApiError
     */
    public static apiAdminsmsTemplatesGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<SmsTemplate_sms_template_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/sms-templates',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Retrieves a SmsTemplate resource.
     * Retrieves a SmsTemplate resource.
     * @returns SmsTemplate_sms_template_read SmsTemplate resource
     * @throws ApiError
     */
    public static apiAdminsmsTemplatesIdGet({
        id,
    }: {
        /**
         * SmsTemplate identifier
         */
        id: string,
    }): CancelablePromise<SmsTemplate_sms_template_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/sms-templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the SmsTemplate resource.
     * Replaces the SmsTemplate resource.
     * @returns SmsTemplate_sms_template_read SmsTemplate resource updated
     * @throws ApiError
     */
    public static apiAdminsmsTemplatesIdPut({
        id,
        requestBody,
    }: {
        /**
         * SmsTemplate identifier
         */
        id: string,
        /**
         * The updated SmsTemplate resource
         */
        requestBody: SmsTemplate_SmsTemplateDto,
    }): CancelablePromise<SmsTemplate_sms_template_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/sms-templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

}
