import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex h-100'>
      <div className='d-flex justify-content-center flex-lg-row-fluid m-auto'>
        <div className='w-lg-500px p-10'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
