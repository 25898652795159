import {SidebarMenuMain} from './SidebarMenuMain'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../../app/modules/auth'
import {PERMISSION_SUPER_ADMIN, PERMISSION_USERS} from '../../../../../utils/permissions'

const SidebarMenu = () => {
  const {currentUser, logout} = useAuth()
  const id = currentUser?.id

  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid d-flex'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y py-5 d-flex flex-wrap overflow-auto'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div className='menu menu-column menu-rounded menu-sub-indention px-3 w-100' id='#kt_app_sidebar_menu' data-kt-menu='true' data-kt-menu-expand='false'>
          <SidebarMenuMain />
        </div>
        <div className='d-flex flex-column gap-3 px-3 mt-auto'>
          {(currentUser?.permissions?.includes(PERMISSION_USERS) || currentUser?.permissions?.includes(PERMISSION_SUPER_ADMIN)) && (
            <Link to={`/users/update/${id}`} className='btn btn-text text-light btn-sm text-start'>My Profile</Link>
          )}
          <div onClick={logout}  className='btn btn-text text-light btn-sm text-start'>Sign Out</div>
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}
