/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_jsonld_reservation_write } from './Addon_jsonld_reservation_write';
import type { ReservationAddressDto_jsonld_reservation_write } from './ReservationAddressDto_jsonld_reservation_write';
import type { ReservationSurveyDto_jsonld_reservation_write } from './ReservationSurveyDto_jsonld_reservation_write';

export type Reservation_ReservationDto_jsonld_reservation_write = {
    startAt: string | null;
    endAt: string | null;
    boatGroup: string | null;
    cruiseType: Reservation_ReservationDto_jsonld_reservation_write.cruiseType | null;
    seats?: number | null;
    isSuperoverride?: boolean | null;
    boat?: string | null;
    cruise?: string | null;
    addons?: Array<Addon_jsonld_reservation_write>;
    hasCancellation: boolean | null;
    insurance?: string | null;
    promocode?: string | null;
    boatPassCode?: string | null;
    tipPercent?: number | null;
    survey: ReservationSurveyDto_jsonld_reservation_write | null;
    customerAddress: ReservationAddressDto_jsonld_reservation_write | null;
    billingAddress?: ReservationAddressDto_jsonld_reservation_write | null;
    isWalkIn?: boolean | null;
    notes?: string | null;
    hasEmailNewsletter?: boolean | null;
};

export namespace Reservation_ReservationDto_jsonld_reservation_write {

    export enum cruiseType {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

