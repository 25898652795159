/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MonthDaysDto_override_write } from './MonthDaysDto_override_write';
import type { YearDatesDto_override_write } from './YearDatesDto_override_write';

export type Override_OverrideDto_override_write = {
    name: string | null;
    boats: Array<string>;
    frequency: Override_OverrideDto_override_write.frequency | null;
    startAt?: string | null;
    endAt?: string | null;
    yearDates?: YearDatesDto_override_write | null;
    monthDays?: MonthDaysDto_override_write | null;
    weekDays?: Array<number>;
    customDates?: Array<string>;
    timeFrom?: string | null;
    timeTo?: string | null;
};

export namespace Override_OverrideDto_override_write {

    export enum frequency {
        YEARLY = 'yearly',
        MONTHLY = 'monthly',
        WEEKLY = 'weekly',
        CUSTOM_DATES = 'custom_dates',
    }


}

