/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DayPriceDto_jsonld_boat_group_write } from './DayPriceDto_jsonld_boat_group_write';

export type FlexibleTimeSlotDto_jsonld_boat_group_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: FlexibleTimeSlotDto_jsonld_boat_group_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    timeStep: FlexibleTimeSlotDto_jsonld_boat_group_write.timeStep | null;
    defaultPrice: number | null;
    prices?: Array<DayPriceDto_jsonld_boat_group_write>;
    minReservationTime: number | null;
    maxReservationTime: number | null;
};

export namespace FlexibleTimeSlotDto_jsonld_boat_group_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum timeStep {
        '_15' = 15,
        '_30' = 30,
        '_45' = 45,
        '_60' = 60,
    }


}

