/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tax_tax_read } from '../models/Tax_tax_read';
import type { Tax_TaxDto_tax_write } from '../models/Tax_TaxDto_tax_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxService {

    /**
     * Retrieves the collection of Tax resources.
     * Retrieves the collection of Tax resources.
     * @returns Tax_tax_read Tax collection
     * @throws ApiError
     */
    public static apiAdmintaxesGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Tax_tax_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/taxes',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a Tax resource.
     * Creates a Tax resource.
     * @returns Tax_tax_read Tax resource created
     * @throws ApiError
     */
    public static apiAdmintaxesPost({
        requestBody,
    }: {
        /**
         * The new Tax resource
         */
        requestBody: Tax_TaxDto_tax_write,
    }): CancelablePromise<Tax_tax_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/taxes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Tax resource.
     * Retrieves a Tax resource.
     * @returns Tax_tax_read Tax resource
     * @throws ApiError
     */
    public static apiAdmintaxesIdGet({
        id,
    }: {
        /**
         * Tax identifier
         */
        id: string,
    }): CancelablePromise<Tax_tax_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/taxes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Tax resource.
     * Replaces the Tax resource.
     * @returns Tax_tax_read Tax resource updated
     * @throws ApiError
     */
    public static apiAdmintaxesIdPut({
        id,
        requestBody,
    }: {
        /**
         * Tax identifier
         */
        id: string,
        /**
         * The updated Tax resource
         */
        requestBody: Tax_TaxDto_tax_write,
    }): CancelablePromise<Tax_tax_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/taxes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the Tax resource.
     * Removes the Tax resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdmintaxesIdDelete({
        id,
    }: {
        /**
         * Tax identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/taxes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
