/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Timetable_jsonld_location_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Timetable_jsonld_location_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    /**
     * ISO 8601 numeric representation of the day of the week
     * 1 (for Monday) through 7 (for Sunday).
     */
    day?: number;
    openingTime?: string;
    closingTime?: string;
};

export namespace Timetable_jsonld_location_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

