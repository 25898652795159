/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_reservation_receipt_write } from './Addon_reservation_receipt_write';

export type Reservation_ReservationReceiptDto_reservation_receipt_write = {
    startAt: string | null;
    endAt: string | null;
    boatGroup: string | null;
    cruiseType: Reservation_ReservationReceiptDto_reservation_receipt_write.cruiseType | null;
    seats?: number | null;
    addons?: Array<Addon_reservation_receipt_write>;
    hasCancellation: boolean | null;
    insurance?: string | null;
    promocode?: string | null;
    boatPassCode?: string | null;
    tipPercent?: number | null;
    reservationId?: number | null;
};

export namespace Reservation_ReservationReceiptDto_reservation_receipt_write {

    export enum cruiseType {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

