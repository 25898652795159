/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TimetableDto_jsonld_boat_group_write } from './TimetableDto_jsonld_boat_group_write';

export type ScheduleDto_jsonld_boat_group_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ScheduleDto_jsonld_boat_group_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    monthFrom: number | null;
    dayFrom: number | null;
    monthTo: number | null;
    dayTo: number | null;
    timetables: Array<TimetableDto_jsonld_boat_group_write>;
};

export namespace ScheduleDto_jsonld_boat_group_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

