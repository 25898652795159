/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TimeRangePrice_jsonld_boat_group_read } from './TimeRangePrice_jsonld_boat_group_read';

export type DayPrice_jsonld_boat_group_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: DayPrice_jsonld_boat_group_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    day?: number;
    readonly price?: number | null;
    prices?: Array<TimeRangePrice_jsonld_boat_group_read>;
};

export namespace DayPrice_jsonld_boat_group_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

