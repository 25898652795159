/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BoatPassAddress_jsonld_boat_pass_read } from './BoatPassAddress_jsonld_boat_pass_read';
import type { BoatPassLog_jsonld_boat_pass_read } from './BoatPassLog_jsonld_boat_pass_read';
import type { BoatPassReceipt_jsonld_boat_pass_read } from './BoatPassReceipt_jsonld_boat_pass_read';
import type { BoatPassType_jsonld_boat_pass_read } from './BoatPassType_jsonld_boat_pass_read';
import type { Payment_jsonld_boat_pass_read } from './Payment_jsonld_boat_pass_read';

export type BoatPass_jsonld_boat_pass_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatPass_jsonld_boat_pass_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    type?: BoatPassType_jsonld_boat_pass_read;
    code?: string;
    receipt?: BoatPassReceipt_jsonld_boat_pass_read;
    customerAddress?: BoatPassAddress_jsonld_boat_pass_read;
    billingAddress?: BoatPassAddress_jsonld_boat_pass_read | null;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    readonly remainingCruises?: number;
    payment?: Payment_jsonld_boat_pass_read | null;
    logs?: Array<BoatPassLog_jsonld_boat_pass_read>;
    readonly status?: BoatPass_jsonld_boat_pass_read.status;
};

export namespace BoatPass_jsonld_boat_pass_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum status {
        PROCESSING = 'processing',
        PAID = 'paid',
        FAILED = 'failed',
    }


}

