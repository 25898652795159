/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Boat_boat_read } from '../models/Boat_boat_read';
import type { Boat_BoatDto_boat_write } from '../models/Boat_BoatDto_boat_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BoatService {

    /**
     * Retrieves the collection of Boat resources.
     * Retrieves the collection of Boat resources.
     * @returns Boat_boat_read Boat collection
     * @throws ApiError
     */
    public static apiAdminboatsGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Boat_boat_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boats',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a Boat resource.
     * Creates a Boat resource.
     * @returns Boat_boat_read Boat resource created
     * @throws ApiError
     */
    public static apiAdminboatsPost({
        requestBody,
    }: {
        /**
         * The new Boat resource
         */
        requestBody: Boat_BoatDto_boat_write,
    }): CancelablePromise<Boat_boat_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/boats',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Boat resource.
     * Retrieves a Boat resource.
     * @returns Boat_boat_read Boat resource
     * @throws ApiError
     */
    public static apiAdminboatsIdGet({
        id,
    }: {
        /**
         * Boat identifier
         */
        id: string,
    }): CancelablePromise<Boat_boat_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boats/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Boat resource.
     * Replaces the Boat resource.
     * @returns Boat_boat_read Boat resource updated
     * @throws ApiError
     */
    public static apiAdminboatsIdPut({
        id,
        requestBody,
    }: {
        /**
         * Boat identifier
         */
        id: string,
        /**
         * The updated Boat resource
         */
        requestBody: Boat_BoatDto_boat_write,
    }): CancelablePromise<Boat_boat_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/boats/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the Boat resource.
     * Removes the Boat resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminboatsIdDelete({
        id,
    }: {
        /**
         * Boat identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/boats/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves a Boat resource.
     * Retrieves a Boat resource.
     * @returns void
     * @throws ApiError
     */
    public static apiClientboatsIdGet({
        id,
    }: {
        /**
         * Boat identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/boats/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
