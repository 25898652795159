/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Reservation_jsonld_reservation_read } from './Reservation_jsonld_reservation_read';

export type Reservation_CreatedReservationOutput_jsonld_reservation_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Reservation_CreatedReservationOutput_jsonld_reservation_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    reservation?: Reservation_jsonld_reservation_read;
    clientSecret?: string | null;
};

export namespace Reservation_CreatedReservationOutput_jsonld_reservation_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

