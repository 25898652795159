import {FC, useState, useEffect, createContext, useContext} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {WithChildren} from '../../../../_metronic/helpers'
import {User_user_read, Credentials, UserService, AdminAuthenticationService} from '../../../../api'
import notification from '../../../../utils/notification'

type AuthContextProps = {
  isAuth: boolean
  currentUser: User_user_read | undefined
  login: (data: Credentials) => void
  logout: () => void
  getMe: () => void
}

const initAuthContextPropsState = {
  isAuth: false,
  currentUser: undefined,
  login: () => {},
  logout: () => {},
  getMe: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [currentUser, setCurrentUser] = useState<User_user_read | undefined>()
  const [isAuth, setIsAuth] = useState<boolean>(false)

  const getMe = async () => {
    try {
      const data = await UserService.apiAdminmeGet()

      setCurrentUser(data)
      setIsAuth(true)
    } catch (error) {
      console.error(error)
    }
  }

  const login = async (values: Credentials) => {
    try {
      await AdminAuthenticationService.postAdminLogin({
        requestBody: values,
      })

      try {
        await getMe()
      } catch (error) {
        console.error(error)
      }
    } catch (error: any) {
      notification('error', error.body.error)
    }
  }

  const logout = async () => {
    document.body.style.opacity = '0.85'
    document.body.style.pointerEvents = 'none'

    try {
      await AdminAuthenticationService.postAdminLogout()
      setIsAuth(false)
      setCurrentUser(undefined)
    } catch (error) {
      console.error(error)
    } finally {
      document.location.reload()
    }
  }

  return <AuthContext.Provider value={{isAuth, currentUser, login, logout, getMe}}>{children}</AuthContext.Provider>
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {getMe, logout} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async () => {
      try {
        await getMe()
      } catch (error) {
        await logout()
        console.error(error)
      } finally {
        setShowSplashScreen(false)
      }
    }

    requestUser()
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
