/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PromotionCode_promotion_read } from './PromotionCode_promotion_read';
import type { PromotionTypeSettings_promotion_read } from './PromotionTypeSettings_promotion_read';
import type { TimeRestriction_promotion_read } from './TimeRestriction_promotion_read';
import type { VoucherTypeSettings_promotion_read } from './VoucherTypeSettings_promotion_read';

export type Promotion_promotion_read = {
    readonly id?: number;
    name?: string;
    description?: string;
    isActive?: boolean;
    validFrom?: string;
    validTo?: string;
    promotionType?: PromotionTypeSettings_promotion_read | null;
    voucherType?: VoucherTypeSettings_promotion_read | null;
    isBookingFeeFree?: boolean;
    isTipOptional?: boolean;
    codes?: Array<PromotionCode_promotion_read>;
    purchaseRestriction?: TimeRestriction_promotion_read;
    cruiseRestriction?: TimeRestriction_promotion_read;
    minReservationTime?: number | null;
    maxReservationTime?: number | null;
    boatGroups?: Array<string>;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    deletedAt?: string | null;
    usage?: string | null;
    readonly type?: Promotion_promotion_read.type;
    readonly cruiseType?: string;
};

export namespace Promotion_promotion_read {

    export enum type {
        PROMOTION = 'promotion',
        VOUCHER = 'voucher',
    }


}

