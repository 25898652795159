/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Refund_jsonld_cruise_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Refund_jsonld_cruise_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    createdAt?: string;
    status?: string;
    amount?: number;
    error?: string | null;
    externalRefundId?: string;
    externalChargeId?: string;
};

export namespace Refund_jsonld_cruise_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

