/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QrCode_qr_code_read } from '../models/QrCode_qr_code_read';
import type { QrCode_QrCodeDto_qr_code_write } from '../models/QrCode_QrCodeDto_qr_code_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QrCodeService {

    /**
     * Retrieves the collection of QrCode resources.
     * Retrieves the collection of QrCode resources.
     * @returns QrCode_qr_code_read QrCode collection
     * @throws ApiError
     */
    public static apiAdminqrCodesGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<QrCode_qr_code_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/qr-codes',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a QrCode resource.
     * Creates a QrCode resource.
     * @returns QrCode_qr_code_read QrCode resource created
     * @throws ApiError
     */
    public static apiAdminqrCodesPost({
        requestBody,
    }: {
        /**
         * The new QrCode resource
         */
        requestBody: QrCode_QrCodeDto_qr_code_write,
    }): CancelablePromise<QrCode_qr_code_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/qr-codes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a QrCode resource.
     * Retrieves a QrCode resource.
     * @returns QrCode_qr_code_read QrCode resource
     * @throws ApiError
     */
    public static apiAdminqrCodesIdGet({
        id,
    }: {
        /**
         * QrCode identifier
         */
        id: string,
    }): CancelablePromise<QrCode_qr_code_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/qr-codes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the QrCode resource.
     * Replaces the QrCode resource.
     * @returns QrCode_qr_code_read QrCode resource updated
     * @throws ApiError
     */
    public static apiAdminqrCodesIdPut({
        id,
        requestBody,
    }: {
        /**
         * QrCode identifier
         */
        id: string,
        /**
         * The updated QrCode resource
         */
        requestBody: QrCode_QrCodeDto_qr_code_write,
    }): CancelablePromise<QrCode_qr_code_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/qr-codes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the QrCode resource.
     * Removes the QrCode resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminqrCodesIdDelete({
        id,
    }: {
        /**
         * QrCode identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/qr-codes/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
