/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReservationLog_reservation_log_read } from '../models/ReservationLog_reservation_log_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReservationLogService {

    /**
     * Retrieves the collection of ReservationLog resources.
     * Retrieves the collection of ReservationLog resources.
     * @returns ReservationLog_reservation_log_read ReservationLog collection
     * @throws ApiError
     */
    public static apiAdminreservationLogsGetCollection({
        page = 1,
        reservation,
        userEmail,
        createdAtBefore,
        createdAtStrictlyBefore,
        createdAtAfter,
        createdAtStrictlyAfter,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        reservation?: Array<string>,
        userEmail?: string,
        createdAtBefore?: string,
        createdAtStrictlyBefore?: string,
        createdAtAfter?: string,
        createdAtStrictlyAfter?: string,
    }): CancelablePromise<Array<ReservationLog_reservation_log_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reservation-logs',
            query: {
                'page': page,
                'reservation[]': reservation,
                'userEmail': userEmail,
                'createdAt[before]': createdAtBefore,
                'createdAt[strictly_before]': createdAtStrictlyBefore,
                'createdAt[after]': createdAtAfter,
                'createdAt[strictly_after]': createdAtStrictlyAfter,
            },
        });
    }

}
