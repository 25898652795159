/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContactForm_jsonld = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ContactForm_jsonld.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    message: string | null;
};

export namespace ContactForm_jsonld {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

