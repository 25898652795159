/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PromotionTypeDto_promotion_write = {
    usage: PromotionTypeDto_promotion_write.usage | null;
    discountAmount: number | null;
    discountType: PromotionTypeDto_promotion_write.discountType | null;
};

export namespace PromotionTypeDto_promotion_write {

    export enum usage {
        SINGLE = 'single',
        SINGLE_PER_USER = 'single_per_user',
        MULTIPLE = 'multiple',
    }

    export enum discountType {
        DOLLAR = 'dollar',
        PERCENT = 'percent',
    }


}

