/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AvailableTimeSlot_jsonld_available_cruise_read } from './AvailableTimeSlot_jsonld_available_cruise_read';
import type { BoatGroup_jsonld_available_cruise_read } from './BoatGroup_jsonld_available_cruise_read';

export type AvailableBoatGroup_jsonld_available_cruise_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: AvailableBoatGroup_jsonld_available_cruise_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    boatGroup?: BoatGroup_jsonld_available_cruise_read;
    timeSlotType?: string;
    priceFrom?: number;
    timeSlots?: Array<AvailableTimeSlot_jsonld_available_cruise_read>;
};

export namespace AvailableBoatGroup_jsonld_available_cruise_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

