/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Addon_reservation_read = {
    readonly id?: number;
    name?: string;
    description?: string;
    readonly price?: number;
    readonly priceType?: Addon_reservation_read.priceType;
};

export namespace Addon_reservation_read {

    export enum priceType {
        FIXED = 'fixed',
        HOURLY = 'hourly',
    }


}

