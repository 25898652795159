/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BoatPassType_jsonld_reservation_read } from './BoatPassType_jsonld_reservation_read';

export type BoatPass_jsonld_reservation_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatPass_jsonld_reservation_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    type?: BoatPassType_jsonld_reservation_read;
    code?: string;
    readonly remainingCruises?: number;
};

export namespace BoatPass_jsonld_reservation_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

