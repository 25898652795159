/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FlexibleTimeSlotDto_jsonld_boat_group_write } from './FlexibleTimeSlotDto_jsonld_boat_group_write';
import type { ManualTimeSlotDto_jsonld_boat_group_write } from './ManualTimeSlotDto_jsonld_boat_group_write';

export type PrivateTypeDto_jsonld_boat_group_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: PrivateTypeDto_jsonld_boat_group_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    timeSlotType: PrivateTypeDto_jsonld_boat_group_write.timeSlotType | null;
    flexibleTimeSlot?: FlexibleTimeSlotDto_jsonld_boat_group_write | null;
    manualTimeSlots?: Array<ManualTimeSlotDto_jsonld_boat_group_write>;
    cancellationCost?: number | null;
    /**
     * Time before the cruise (in minutes).
     */
    preBookingTime: number | null;
    requiredAddons?: Array<string>;
    hiddenAddons?: Array<string>;
    securityDeposit?: number | null;
    contentPriceTable?: string | null;
};

export namespace PrivateTypeDto_jsonld_boat_group_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum timeSlotType {
        FLEXIBLE = 'flexible',
        MANUAL = 'manual',
    }


}

