/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BookedReasonQuery_booked_reason_write = {
    cruiseType: BookedReasonQuery_booked_reason_write.cruiseType | null;
    boatGroup: string | null;
    startAt: string | null;
    endAt: string | null;
    excludedReservationId?: number | null;
};

export namespace BookedReasonQuery_booked_reason_write {

    export enum cruiseType {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

