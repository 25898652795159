/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Reservation_ReservationCancelDto_reservation_write = {
    refundType: Reservation_ReservationCancelDto_reservation_write.refundType | null;
    refundAmount?: number | null;
};

export namespace Reservation_ReservationCancelDto_reservation_write {

    export enum refundType {
        ENTIRE_REFUND = 'entire_refund',
        FULL_REFUND = 'full_refund',
        PARTIAL_REFUND = 'partial_refund',
        NO_REFUND = 'no_refund',
    }


}

