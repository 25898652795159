/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReservationAddress_jsonld_cruise_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ReservationAddress_jsonld_cruise_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
};

export namespace ReservationAddress_jsonld_cruise_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

