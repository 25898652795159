/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Reservation_CreatedReservationOutput_reservation_read } from '../models/Reservation_CreatedReservationOutput_reservation_read';
import type { Reservation_EmptyDto_reservation_write } from '../models/Reservation_EmptyDto_reservation_write';
import type { Reservation_reservation_read } from '../models/Reservation_reservation_read';
import type { Reservation_ReservationBoatPassValidation_reservation_boat_pass_validation_read } from '../models/Reservation_ReservationBoatPassValidation_reservation_boat_pass_validation_read';
import type { Reservation_ReservationCancelDto_reservation_write } from '../models/Reservation_ReservationCancelDto_reservation_write';
import type { Reservation_ReservationDto_reservation_write } from '../models/Reservation_ReservationDto_reservation_write';
import type { Reservation_ReservationNotesDto_reservation_write } from '../models/Reservation_ReservationNotesDto_reservation_write';
import type { Reservation_ReservationPromocodeValidation_reservation_promocode_validation_read } from '../models/Reservation_ReservationPromocodeValidation_reservation_promocode_validation_read';
import type { Reservation_ReservationReceipt_reservation_receipt_read } from '../models/Reservation_ReservationReceipt_reservation_receipt_read';
import type { Reservation_ReservationReceiptDto_reservation_receipt_write } from '../models/Reservation_ReservationReceiptDto_reservation_receipt_write';
import type { Reservation_ReservationSecurityDepositCaptureDto_reservation_write } from '../models/Reservation_ReservationSecurityDepositCaptureDto_reservation_write';
import type { Reservation_Subscriber_reservation_read } from '../models/Reservation_Subscriber_reservation_read';
import type { Reservation_UpdatedReservationOutput_reservation_read } from '../models/Reservation_UpdatedReservationOutput_reservation_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReservationService {

    /**
     * Retrieves a Reservation resource.
     * Retrieves a Reservation resource.
     * @returns Reservation_ReservationPromocodeValidation_reservation_promocode_validation_read Reservation resource
     * @throws ApiError
     */
    public static apiAdminreservationPromocodeValidationGet({
        json,
    }: {
        /**
         * Reservation Promocode Validation Query Json
         */
        json: string,
    }): CancelablePromise<Reservation_ReservationPromocodeValidation_reservation_promocode_validation_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reservation-promocode-validation',
            query: {
                'json': json,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Creates a Reservation resource.
     * Creates a Reservation resource.
     * @returns Reservation_ReservationReceipt_reservation_receipt_read Reservation resource created
     * @throws ApiError
     */
    public static apiAdminreservationReceiptsPost({
        requestBody,
    }: {
        /**
         * The new Reservation resource
         */
        requestBody: Reservation_ReservationReceiptDto_reservation_receipt_write,
    }): CancelablePromise<Reservation_ReservationReceipt_reservation_receipt_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/reservation-receipts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a Reservation resource.
     * Creates a Reservation resource.
     * @returns Reservation_CreatedReservationOutput_reservation_read Reservation resource created
     * @throws ApiError
     */
    public static apiAdminreservationsPost({
        requestBody,
    }: {
        /**
         * The new Reservation resource
         */
        requestBody: Reservation_ReservationDto_reservation_write,
    }): CancelablePromise<Reservation_CreatedReservationOutput_reservation_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/reservations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Reservation resource.
     * Retrieves a Reservation resource.
     * @returns Reservation_reservation_read Reservation resource
     * @throws ApiError
     */
    public static apiAdminreservationsIdGet({
        id,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
    }): CancelablePromise<Reservation_reservation_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/reservations/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Reservation resource.
     * Replaces the Reservation resource.
     * @returns Reservation_UpdatedReservationOutput_reservation_read Reservation resource updated
     * @throws ApiError
     */
    public static apiAdminreservationsIdPut({
        id,
        requestBody,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
        /**
         * The updated Reservation resource
         */
        requestBody: Reservation_ReservationDto_reservation_write,
    }): CancelablePromise<Reservation_UpdatedReservationOutput_reservation_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/reservations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Replaces the Reservation resource.
     * Replaces the Reservation resource.
     * @returns Reservation_UpdatedReservationOutput_reservation_read Reservation resource updated
     * @throws ApiError
     */
    public static apiAdminreservationsIdcancelPut({
        id,
        requestBody,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
        /**
         * The updated Reservation resource
         */
        requestBody: Reservation_ReservationCancelDto_reservation_write,
    }): CancelablePromise<Reservation_UpdatedReservationOutput_reservation_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/reservations/{id}/cancel',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Replaces the Reservation resource.
     * Replaces the Reservation resource.
     * @returns Reservation_reservation_read Reservation resource updated
     * @throws ApiError
     */
    public static apiAdminreservationsIdnotesPut({
        id,
        requestBody,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
        /**
         * The updated Reservation resource
         */
        requestBody: Reservation_ReservationNotesDto_reservation_write,
    }): CancelablePromise<Reservation_reservation_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/reservations/{id}/notes',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a Reservation resource.
     * Creates a Reservation resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminreservationsIdresendConfirmationEmailPost({
        id,
        requestBody,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
        /**
         * The new Reservation resource
         */
        requestBody: Reservation_EmptyDto_reservation_write,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/reservations/{id}/resend-confirmation-email',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a Reservation resource.
     * Creates a Reservation resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminreservationsIdresendReceiptEmailPost({
        id,
        requestBody,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
        /**
         * The new Reservation resource
         */
        requestBody: Reservation_EmptyDto_reservation_write,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/reservations/{id}/resend-receipt-email',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a Reservation resource.
     * Creates a Reservation resource.
     * @returns Reservation_reservation_read Reservation resource created
     * @throws ApiError
     */
    public static apiAdminreservationsIdsecurityDepositCapturePost({
        id,
        requestBody,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
        /**
         * The new Reservation resource
         */
        requestBody: Reservation_ReservationSecurityDepositCaptureDto_reservation_write,
    }): CancelablePromise<Reservation_reservation_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/reservations/{id}/security-deposit-capture',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves the collection of Reservation resources.
     * Retrieves the collection of Reservation resources.
     * @returns Reservation_Subscriber_reservation_read Reservation collection
     * @throws ApiError
     */
    public static apiAdminsubscribersGetCollection(): CancelablePromise<Array<Reservation_Subscriber_reservation_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/subscribers',
        });
    }

    /**
     * Retrieves a Reservation resource.
     * Retrieves a Reservation resource.
     * @returns Reservation_ReservationBoatPassValidation_reservation_boat_pass_validation_read Reservation resource
     * @throws ApiError
     */
    public static apiClientreservationBoatPassValidationGet(): CancelablePromise<Reservation_ReservationBoatPassValidation_reservation_boat_pass_validation_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/reservation-boat-pass-validation',
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves a Reservation resource.
     * Retrieves a Reservation resource.
     * @returns Reservation_ReservationPromocodeValidation_reservation_promocode_validation_read Reservation resource
     * @throws ApiError
     */
    public static apiClientreservationPromocodeValidationGet({
        json,
    }: {
        /**
         * Reservation Promocode Validation Query Json
         */
        json: string,
    }): CancelablePromise<Reservation_ReservationPromocodeValidation_reservation_promocode_validation_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/reservation-promocode-validation',
            query: {
                'json': json,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Creates a Reservation resource.
     * Creates a Reservation resource.
     * @returns Reservation_ReservationReceipt_reservation_receipt_read Reservation resource created
     * @throws ApiError
     */
    public static apiClientreservationReceiptsPost({
        requestBody,
    }: {
        /**
         * The new Reservation resource
         */
        requestBody: Reservation_ReservationReceiptDto_reservation_receipt_write,
    }): CancelablePromise<Reservation_ReservationReceipt_reservation_receipt_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client/reservation-receipts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a Reservation resource.
     * Creates a Reservation resource.
     * @returns Reservation_CreatedReservationOutput_reservation_read Reservation resource created
     * @throws ApiError
     */
    public static apiClientreservationsPost({
        requestBody,
    }: {
        /**
         * The new Reservation resource
         */
        requestBody: Reservation_ReservationDto_reservation_write,
    }): CancelablePromise<Reservation_CreatedReservationOutput_reservation_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/client/reservations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Reservation resource.
     * Retrieves a Reservation resource.
     * @returns void
     * @throws ApiError
     */
    public static apiClientreservationsIdGet({
        id,
    }: {
        /**
         * Reservation identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/reservations/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
