/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SecurityDeposit_jsonld_reservation_log_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: SecurityDeposit_jsonld_reservation_log_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    lockTime?: string;
    unlockTime?: string;
    externalCustomerId?: string;
    externalIntentId?: string | null;
    status?: string;
    lockError?: string | null;
    readonly unlockError?: string | null;
    readonly dollarAmount?: number;
    readonly dollarCapturedAmount?: number | null;
    readonly dollarRefundedAmount?: number | null;
    readonly canCapture?: boolean;
};

export namespace SecurityDeposit_jsonld_reservation_log_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

