/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingFee_jsonld_reservation_log_read } from './BookingFee_jsonld_reservation_log_read';
import type { ReservationAddonReceipt_jsonld_reservation_log_read } from './ReservationAddonReceipt_jsonld_reservation_log_read';
import type { ReservationInsuranceReceipt_jsonld_reservation_log_read } from './ReservationInsuranceReceipt_jsonld_reservation_log_read';
import type { ReservationPromotionReceipt_jsonld_reservation_log_read } from './ReservationPromotionReceipt_jsonld_reservation_log_read';
import type { ReservationTipReceipt_jsonld_reservation_log_read } from './ReservationTipReceipt_jsonld_reservation_log_read';

export type ReservationReceipt_jsonld_reservation_log_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ReservationReceipt_jsonld_reservation_log_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly cruisePricePerSeat?: number | null;
    seats?: number | null;
    readonly cruiseTotal?: number;
    readonly cruiseTotalWithDiscount?: number | null;
    promotion?: ReservationPromotionReceipt_jsonld_reservation_log_read;
    hasBoatPass?: boolean;
    addons?: Array<ReservationAddonReceipt_jsonld_reservation_log_read>;
    readonly addonsSubtotal?: number | null;
    readonly addonsTotal?: number | null;
    readonly cancellationCost?: number | null;
    readonly bookingFee?: number;
    settingBookingFee?: BookingFee_jsonld_reservation_log_read;
    tip?: ReservationTipReceipt_jsonld_reservation_log_read;
    insurance?: ReservationInsuranceReceipt_jsonld_reservation_log_read;
    readonly subtotal?: number;
    readonly taxRate?: number;
    readonly tax?: number;
    readonly taxesTotal?: number;
    readonly total?: number;
    readonly refundTotal?: number;
};

export namespace ReservationReceipt_jsonld_reservation_log_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

