/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Boat_jsonld_reservation_log_read } from './Boat_jsonld_reservation_log_read';

export type Cruise_jsonld_reservation_log_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Cruise_jsonld_reservation_log_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    boat?: Boat_jsonld_reservation_log_read;
    isSuperoverride?: boolean;
    startAt?: string;
    endAt?: string;
    readonly type?: string;
};

export namespace Cruise_jsonld_reservation_log_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

