/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AvailableTimeSlot_jsonld_available_cruise_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: AvailableTimeSlot_jsonld_available_cruise_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    length?: number;
    startAt?: string;
    endAt?: string;
    status?: string;
    price?: number | null;
};

export namespace AvailableTimeSlot_jsonld_available_cruise_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

