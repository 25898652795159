/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReservationTipReceipt_jsonld_reservation_receipt_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ReservationTipReceipt_jsonld_reservation_receipt_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly percent?: number | null;
    readonly amount?: number | null;
};

export namespace ReservationTipReceipt_jsonld_reservation_receipt_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

