/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BookingFeeDto = {
    amount: number | null;
    /**
     * Fee Type (dollar or percent).
     */
    type: BookingFeeDto.type | null;
};

export namespace BookingFeeDto {

    /**
     * Fee Type (dollar or percent).
     */
    export enum type {
        DOLLAR = 'dollar',
        PERCENT = 'percent',
    }


}

