/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HowDidYouHear_jsonld_reservation_log_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: HowDidYouHear_jsonld_reservation_log_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
};

export namespace HowDidYouHear_jsonld_reservation_log_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

