/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BlockField_jsonld_block_read } from './BlockField_jsonld_block_read';

export type Block_jsonld_block_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Block_jsonld_block_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    key?: string;
    name?: string;
    fields?: Array<BlockField_jsonld_block_read>;
};

export namespace Block_jsonld_block_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

