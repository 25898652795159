/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_reservation_read } from './Addon_reservation_read';
import type { BoatPass_reservation_read } from './BoatPass_reservation_read';
import type { Cruise_reservation_read } from './Cruise_reservation_read';
import type { Insurance_reservation_read } from './Insurance_reservation_read';
import type { Payment_reservation_read } from './Payment_reservation_read';
import type { ReservationAddress_reservation_read } from './ReservationAddress_reservation_read';
import type { ReservationPromocode_reservation_read } from './ReservationPromocode_reservation_read';
import type { ReservationReceipt_reservation_read } from './ReservationReceipt_reservation_read';
import type { ReservationSurvey_reservation_read } from './ReservationSurvey_reservation_read';
import type { SecurityDeposit_reservation_read } from './SecurityDeposit_reservation_read';

export type Reservation_reservation_read = {
    readonly id?: number;
    cruise?: Cruise_reservation_read;
    seats?: number | null;
    addons?: Array<Addon_reservation_read>;
    hasCancellation?: boolean;
    insurance?: Insurance_reservation_read | null;
    promocode?: ReservationPromocode_reservation_read | null;
    boatPass?: BoatPass_reservation_read | null;
    receipt?: ReservationReceipt_reservation_read;
    survey?: ReservationSurvey_reservation_read;
    customerAddress?: ReservationAddress_reservation_read;
    billingAddress?: ReservationAddress_reservation_read | null;
    notes?: string | null;
    isSuperoverride?: boolean;
    createdAt?: string;
    readonly updatedAt?: string | null;
    payments?: Array<Payment_reservation_read>;
    securityDeposit?: SecurityDeposit_reservation_read | null;
    readonly isWalkIn?: boolean;
    hasEmailNewsletter?: boolean;
    readonly status?: Reservation_reservation_read.status;
    readonly creationSide?: string;
    readonly lastPayment?: Payment_reservation_read | null;
    readonly canEdit?: boolean;
    readonly canCancel?: boolean;
};

export namespace Reservation_reservation_read {

    export enum status {
        PROCESSING = 'processing',
        PAID = 'paid',
        CANCELED = 'canceled',
        FAILED = 'failed',
    }


}

