/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BoatPassAddress_boat_pass_read } from './BoatPassAddress_boat_pass_read';
import type { BoatPassLog_boat_pass_read } from './BoatPassLog_boat_pass_read';
import type { BoatPassReceipt_boat_pass_read } from './BoatPassReceipt_boat_pass_read';
import type { BoatPassType_boat_pass_read } from './BoatPassType_boat_pass_read';
import type { Payment_boat_pass_read } from './Payment_boat_pass_read';

export type BoatPass_boat_pass_read = {
    readonly id?: number;
    type?: BoatPassType_boat_pass_read;
    code?: string;
    receipt?: BoatPassReceipt_boat_pass_read;
    customerAddress?: BoatPassAddress_boat_pass_read;
    billingAddress?: BoatPassAddress_boat_pass_read | null;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    readonly remainingCruises?: number;
    payment?: Payment_boat_pass_read | null;
    logs?: Array<BoatPassLog_boat_pass_read>;
    readonly status?: BoatPass_boat_pass_read.status;
};

export namespace BoatPass_boat_pass_read {

    export enum status {
        PROCESSING = 'processing',
        PAID = 'paid',
        FAILED = 'failed',
    }


}

