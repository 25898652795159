/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddressDto_jsonld_reservation_write } from './AddressDto_jsonld_reservation_write';

export type ReservationAddressDto_jsonld_reservation_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ReservationAddressDto_jsonld_reservation_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    firstName: string | null;
    lastName: string | null;
    address: AddressDto_jsonld_reservation_write | null;
    email: string | null;
    phone: string | null;
};

export namespace ReservationAddressDto_jsonld_reservation_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

