import {FC, lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const SuspenseView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const PrivateRoutes = () => {
  const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'))
  const Profile = lazy(() => import('../pages/profile/Profile'))
  const Users = lazy(() => import('../pages/users/Users'))
  const Settings = lazy(() => import('../pages/settings/Settings'))
  const Block = lazy(() => import('../pages/blocks/Blocks'))
  const Communications = lazy(() => import('../pages/communications/Communications'))
  const Reservations = lazy(() => import('../pages/cruises/Cruises'))
  const Promotions = lazy(() => import('../pages/promotions/Promotions'))
  const Payments = lazy(() => import('../pages/payments/Payments'))
  const Addons = lazy(() => import('../pages/addons/Addons'))
  const Overrides = lazy(() => import('../pages/overrides/Overrides'))
  const Taxes = lazy(() => import('../pages/taxes/Taxes'))
  const Logs = lazy(() => import('../pages/logs/Logs'))
  const BoatManager = lazy(() => import('../pages/boat-management/BoatManager'))
  const Sms = lazy(() => import('../pages/sms/Sms'))
  const PrintReceipt = lazy(() => import('../pages/print-receipt/PrintReceipt'))
  const Reports = lazy(() => import('../pages/reports/Reports'))
  const QrCodes = lazy(() => import('../pages/qr-codes/QrCodes'))
  const BoatPass = lazy(() => import('../pages/boat-pass/BoatPass'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route
          path='dashboard/*'
          element={
            <SuspenseView>
              <Dashboard />
            </SuspenseView>
          }
        />
        <Route
          path='profile'
          element={
            <SuspenseView>
              <Profile />
            </SuspenseView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspenseView>
              <Users />
            </SuspenseView>
          }
        />
        <Route
          path='settings'
          element={
            <SuspenseView>
              <Settings />
            </SuspenseView>
          }
        />
        <Route
          path='qr-codes/*'
          element={
            <SuspenseView>
              <QrCodes />
            </SuspenseView>
          }
        />
        <Route
          path='blocks/*'
          element={
            <SuspenseView>
              <Block />
            </SuspenseView>
          }
        />
        <Route
          path='communications/*'
          element={
            <SuspenseView>
              <Communications />
            </SuspenseView>
          }
        />
        <Route
          path='sms/*'
          element={
            <SuspenseView>
              <Sms />
            </SuspenseView>
          }
        />
        <Route
          path='cruises/*'
          element={
            <SuspenseView>
              <Reservations />
            </SuspenseView>
          }
        />
        <Route
          path='promotions/*'
          element={
            <SuspenseView>
              <Promotions />
            </SuspenseView>
          }
        />
        <Route
          path='payments/*'
          element={
            <SuspenseView>
              <Payments />
            </SuspenseView>
          }
        />
        <Route
          path='addons/*'
          element={
            <SuspenseView>
              <Addons />
            </SuspenseView>
          }
        />
        <Route
          path='overrides/*'
          element={
            <SuspenseView>
              <Overrides />
            </SuspenseView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspenseView>
              <Reports />
            </SuspenseView>
          }
        />
        <Route
          path='taxes/*'
          element={
            <SuspenseView>
              <Taxes />
            </SuspenseView>
          }
        />
        <Route
          path='logs/*'
          element={
            <SuspenseView>
              <Logs />
            </SuspenseView>
          }
        />
        <Route
          path='boat-management/*'
          element={
            <SuspenseView>
              <BoatManager />
            </SuspenseView>
          }
        />
        <Route
          path='boat-pass/*'
          element={
            <SuspenseView>
              <BoatPass />
            </SuspenseView>
          }
        />
        <Route
          path='print-receipt/:id'
          element={
            <SuspenseView>
              <PrintReceipt />
            </SuspenseView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
