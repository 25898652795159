/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Override_override_read } from '../models/Override_override_read';
import type { Override_OverrideBoatGroup_override_boat_read } from '../models/Override_OverrideBoatGroup_override_boat_read';
import type { Override_OverrideDto_override_write } from '../models/Override_OverrideDto_override_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OverrideService {

    /**
     * Retrieves the collection of Override resources.
     * Retrieves the collection of Override resources.
     * @returns Override_OverrideBoatGroup_override_boat_read Override collection
     * @throws ApiError
     */
    public static apiAdminoverrideBoatsGetCollection({
        json,
        name,
        boatsBoatGroup,
        frequency,
        startAtBefore,
        startAtStrictlyBefore,
        startAtAfter,
        startAtStrictlyAfter,
        endAtBefore,
        endAtStrictlyBefore,
        endAtAfter,
        endAtStrictlyAfter,
    }: {
        /**
         * Override Boat Query Json
         */
        json: string,
        name?: string,
        boatsBoatGroup?: Array<string>,
        frequency?: Array<string>,
        startAtBefore?: string,
        startAtStrictlyBefore?: string,
        startAtAfter?: string,
        startAtStrictlyAfter?: string,
        endAtBefore?: string,
        endAtStrictlyBefore?: string,
        endAtAfter?: string,
        endAtStrictlyAfter?: string,
    }): CancelablePromise<Array<Override_OverrideBoatGroup_override_boat_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/override-boats',
            query: {
                'name': name,
                'boats.boatGroup[]': boatsBoatGroup,
                'frequency[]': frequency,
                'startAt[before]': startAtBefore,
                'startAt[strictly_before]': startAtStrictlyBefore,
                'startAt[after]': startAtAfter,
                'startAt[strictly_after]': startAtStrictlyAfter,
                'endAt[before]': endAtBefore,
                'endAt[strictly_before]': endAtStrictlyBefore,
                'endAt[after]': endAtAfter,
                'endAt[strictly_after]': endAtStrictlyAfter,
                'json': json,
            },
        });
    }

    /**
     * Retrieves the collection of Override resources.
     * Retrieves the collection of Override resources.
     * @returns Override_override_read Override collection
     * @throws ApiError
     */
    public static apiAdminoverridesGetCollection({
        page = 1,
        name,
        boatsBoatGroup,
        frequency,
        startAtBefore,
        startAtStrictlyBefore,
        startAtAfter,
        startAtStrictlyAfter,
        endAtBefore,
        endAtStrictlyBefore,
        endAtAfter,
        endAtStrictlyAfter,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        name?: string,
        boatsBoatGroup?: Array<string>,
        frequency?: Array<string>,
        startAtBefore?: string,
        startAtStrictlyBefore?: string,
        startAtAfter?: string,
        startAtStrictlyAfter?: string,
        endAtBefore?: string,
        endAtStrictlyBefore?: string,
        endAtAfter?: string,
        endAtStrictlyAfter?: string,
    }): CancelablePromise<Array<Override_override_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/overrides',
            query: {
                'page': page,
                'name': name,
                'boats.boatGroup[]': boatsBoatGroup,
                'frequency[]': frequency,
                'startAt[before]': startAtBefore,
                'startAt[strictly_before]': startAtStrictlyBefore,
                'startAt[after]': startAtAfter,
                'startAt[strictly_after]': startAtStrictlyAfter,
                'endAt[before]': endAtBefore,
                'endAt[strictly_before]': endAtStrictlyBefore,
                'endAt[after]': endAtAfter,
                'endAt[strictly_after]': endAtStrictlyAfter,
            },
        });
    }

    /**
     * Creates a Override resource.
     * Creates a Override resource.
     * @returns Override_override_read Override resource created
     * @throws ApiError
     */
    public static apiAdminoverridesPost({
        requestBody,
    }: {
        /**
         * The new Override resource
         */
        requestBody: Override_OverrideDto_override_write,
    }): CancelablePromise<Override_override_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/overrides',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Override resource.
     * Retrieves a Override resource.
     * @returns Override_override_read Override resource
     * @throws ApiError
     */
    public static apiAdminoverridesIdGet({
        id,
    }: {
        /**
         * Override identifier
         */
        id: string,
    }): CancelablePromise<Override_override_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/overrides/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Override resource.
     * Replaces the Override resource.
     * @returns Override_override_read Override resource updated
     * @throws ApiError
     */
    public static apiAdminoverridesIdPut({
        id,
        requestBody,
    }: {
        /**
         * Override identifier
         */
        id: string,
        /**
         * The updated Override resource
         */
        requestBody: Override_OverrideDto_override_write,
    }): CancelablePromise<Override_override_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/overrides/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the Override resource.
     * Removes the Override resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminoverridesIdDelete({
        id,
    }: {
        /**
         * Override identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/overrides/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
