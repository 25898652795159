/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddressDto_jsonld_boat_pass_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: AddressDto_jsonld_boat_pass_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    line1: string | null;
    line2?: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    zip: string | null;
};

export namespace AddressDto_jsonld_boat_pass_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

