/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MediaObject_jsonld_block_read } from './MediaObject_jsonld_block_read';

export type BlockField_jsonld_block_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BlockField_jsonld_block_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    key?: string;
    readonly text?: string | null;
    readonly html?: string | null;
    readonly image?: MediaObject_jsonld_block_read | null;
    readonly images?: Array<MediaObject_jsonld_block_read>;
    readonly isEmpty?: boolean;
};

export namespace BlockField_jsonld_block_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

