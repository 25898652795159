/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PriceFrom_jsonld_client_boat_group_read } from './PriceFrom_jsonld_client_boat_group_read';
import type { ReservationTime_jsonld_client_boat_group_read } from './ReservationTime_jsonld_client_boat_group_read';

export type PrivateType_jsonld_client_boat_group_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: PrivateType_jsonld_client_boat_group_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    contentPriceTable?: string | null;
    readonly priceFrom?: PriceFrom_jsonld_client_boat_group_read | null;
    readonly reservationTime?: ReservationTime_jsonld_client_boat_group_read | null;
};

export namespace PrivateType_jsonld_client_boat_group_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

