/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Discount_jsonld_reservation_receipt_read } from './Discount_jsonld_reservation_receipt_read';

export type ReservationPromotionReceipt_jsonld_reservation_receipt_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: ReservationPromotionReceipt_jsonld_reservation_receipt_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly cruiseDiscount?: number | null;
    readonly type?: string | null;
    readonly promotionDiscount?: Discount_jsonld_reservation_receipt_read | null;
    readonly voucherPrice?: number | null;
};

export namespace ReservationPromotionReceipt_jsonld_reservation_receipt_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

