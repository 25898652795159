/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ReservationTaxReceipt_reservation_receipt_read } from './ReservationTaxReceipt_reservation_receipt_read';

export type ReservationAddonReceipt_reservation_receipt_read = {
    addon?: string;
    readonly addonPrice?: number;
    readonly subtotal?: number;
    taxes?: Array<ReservationTaxReceipt_reservation_receipt_read>;
    readonly taxesTotal?: number;
    readonly total?: number;
    readonly addonPriceType?: ReservationAddonReceipt_reservation_receipt_read.addonPriceType;
};

export namespace ReservationAddonReceipt_reservation_receipt_read {

    export enum addonPriceType {
        FIXED = 'fixed',
        HOURLY = 'hourly',
    }


}

