/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MediaObject_jsonld_qr_code_read } from './MediaObject_jsonld_qr_code_read';

export type QrCode_jsonld_qr_code_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: QrCode_jsonld_qr_code_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    file?: MediaObject_jsonld_qr_code_read;
};

export namespace QrCode_jsonld_qr_code_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

