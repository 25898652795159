/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FlexibleTimeSlotDto_boat_group_write } from './FlexibleTimeSlotDto_boat_group_write';
import type { ManualTimeSlotDto_boat_group_write } from './ManualTimeSlotDto_boat_group_write';

export type PrivateTypeDto_boat_group_write = {
    timeSlotType: PrivateTypeDto_boat_group_write.timeSlotType | null;
    flexibleTimeSlot?: FlexibleTimeSlotDto_boat_group_write | null;
    manualTimeSlots?: Array<ManualTimeSlotDto_boat_group_write>;
    cancellationCost?: number | null;
    /**
     * Time before the cruise (in minutes).
     */
    preBookingTime: number | null;
    requiredAddons?: Array<string>;
    hiddenAddons?: Array<string>;
    securityDeposit?: number | null;
    contentPriceTable?: string | null;
};

export namespace PrivateTypeDto_boat_group_write {

    export enum timeSlotType {
        FLEXIBLE = 'flexible',
        MANUAL = 'manual',
    }


}

