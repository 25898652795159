/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Block_block_read } from '../models/Block_block_read';
import type { Block_BlockDto_block_write } from '../models/Block_BlockDto_block_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlockService {

    /**
     * Retrieves the collection of Block resources.
     * Retrieves the collection of Block resources.
     * @returns Block_block_read Block collection
     * @throws ApiError
     */
    public static apiAdminblocksGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Block_block_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/blocks',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Retrieves a Block resource.
     * Retrieves a Block resource.
     * @returns Block_block_read Block resource
     * @throws ApiError
     */
    public static apiAdminblocksIdGet({
        id,
    }: {
        /**
         * Block identifier
         */
        id: string,
    }): CancelablePromise<Block_block_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/blocks/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Block resource.
     * Replaces the Block resource.
     * @returns Block_block_read Block resource updated
     * @throws ApiError
     */
    public static apiAdminblocksIdPut({
        id,
        requestBody,
    }: {
        /**
         * Block identifier
         */
        id: string,
        /**
         * The updated Block resource
         */
        requestBody: Block_BlockDto_block_write,
    }): CancelablePromise<Block_block_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/blocks/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Block resource.
     * Retrieves a Block resource.
     * @returns Block_block_read Block resource
     * @throws ApiError
     */
    public static apiClientblocksKeyGet({
        key,
    }: {
        /**
         * Block identifier
         */
        key: string,
    }): CancelablePromise<Block_block_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/blocks/{key}',
            path: {
                'key': key,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
