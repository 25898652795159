/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmailTemplateVariable_jsonld_email_template_read } from './EmailTemplateVariable_jsonld_email_template_read';

export type EmailTemplate_jsonld_email_template_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: EmailTemplate_jsonld_email_template_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    key?: string;
    name?: string;
    description?: string;
    subject?: string;
    content?: string;
    bcc?: Array<string>;
    variables?: Array<EmailTemplateVariable_jsonld_email_template_read>;
};

export namespace EmailTemplate_jsonld_email_template_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

