/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Boat_jsonld_boat_group_read } from './Boat_jsonld_boat_group_read';
import type { BoatGroupContent_jsonld_boat_group_read } from './BoatGroupContent_jsonld_boat_group_read';
import type { Insurance_jsonld_boat_group_read } from './Insurance_jsonld_boat_group_read';
import type { Location_jsonld_boat_group_read } from './Location_jsonld_boat_group_read';
import type { MediaObject_jsonld_boat_group_read } from './MediaObject_jsonld_boat_group_read';
import type { PrivateType_jsonld_boat_group_read } from './PrivateType_jsonld_boat_group_read';
import type { PublicType_jsonld_boat_group_read } from './PublicType_jsonld_boat_group_read';
import type { Schedule_jsonld_boat_group_read } from './Schedule_jsonld_boat_group_read';

export type BoatGroup_jsonld_boat_group_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatGroup_jsonld_boat_group_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    location?: Location_jsonld_boat_group_read;
    isActive?: boolean;
    description?: string;
    content?: BoatGroupContent_jsonld_boat_group_read;
    capacity?: number;
    readonly isScheduleActive?: boolean;
    readonly schedule?: Schedule_jsonld_boat_group_read | null;
    readonly isPublicType?: boolean;
    readonly isPrivateType?: boolean;
    readonly publicType?: PublicType_jsonld_boat_group_read | null;
    readonly privateType?: PrivateType_jsonld_boat_group_read | null;
    tax?: string;
    image?: MediaObject_jsonld_boat_group_read;
    gallery?: Array<MediaObject_jsonld_boat_group_read>;
    readonly boats?: Array<Boat_jsonld_boat_group_read>;
    deletedAt?: string | null;
    color?: string | null;
    boatOffset?: number | null;
    insurances?: Array<Insurance_jsonld_boat_group_read>;
    isTipAvailable?: boolean;
    calendarName?: string | null;
};

export namespace BoatGroup_jsonld_boat_group_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

