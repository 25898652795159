/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DayPriceDto_boat_group_write } from './DayPriceDto_boat_group_write';

export type FlexibleTimeSlotDto_boat_group_write = {
    timeStep: FlexibleTimeSlotDto_boat_group_write.timeStep | null;
    defaultPrice: number | null;
    prices?: Array<DayPriceDto_boat_group_write>;
    minReservationTime: number | null;
    maxReservationTime: number | null;
};

export namespace FlexibleTimeSlotDto_boat_group_write {

    export enum timeStep {
        '_15' = 15,
        '_30' = 30,
        '_45' = 45,
        '_60' = 60,
    }


}

