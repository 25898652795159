/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaObject_media_object_read } from '../models/MediaObject_media_object_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MediaObjectService {

    /**
     * Retrieves the collection of MediaObject resources.
     * Retrieves the collection of MediaObject resources.
     * @returns MediaObject_media_object_read MediaObject collection
     * @throws ApiError
     */
    public static apiMediaObjectsGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<MediaObject_media_object_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/media-objects',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a MediaObject resource.
     * Creates a MediaObject resource.
     * @returns MediaObject_media_object_read MediaObject resource created
     * @throws ApiError
     */
    public static apiMediaObjectsPost({
        formData,
    }: {
        formData?: {
            file?: Blob;
        },
    }): CancelablePromise<MediaObject_media_object_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/media-objects',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a MediaObject resource.
     * Retrieves a MediaObject resource.
     * @returns MediaObject_media_object_read MediaObject resource
     * @throws ApiError
     */
    public static apiMediaObjectsIdGet({
        id,
    }: {
        /**
         * MediaObject identifier
         */
        id: string,
    }): CancelablePromise<MediaObject_media_object_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/media-objects/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
