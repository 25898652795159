/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_reservation_log_read } from './Addon_reservation_log_read';
import type { ReservationTaxReceipt_reservation_log_read } from './ReservationTaxReceipt_reservation_log_read';

export type ReservationAddonReceipt_reservation_log_read = {
    addon?: Addon_reservation_log_read;
    readonly addonPrice?: number;
    readonly subtotal?: number;
    taxes?: Array<ReservationTaxReceipt_reservation_log_read>;
    readonly taxesTotal?: number;
    readonly total?: number;
    readonly addonPriceType?: ReservationAddonReceipt_reservation_log_read.addonPriceType;
};

export namespace ReservationAddonReceipt_reservation_log_read {

    export enum addonPriceType {
        FIXED = 'fixed',
        HOURLY = 'hourly',
    }


}

