/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmailTemplateVariable_jsonld_email_template_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: EmailTemplateVariable_jsonld_email_template_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    description?: string;
    readonly variable?: string;
};

export namespace EmailTemplateVariable_jsonld_email_template_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

