/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Discount_jsonld_promotion_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Discount_jsonld_promotion_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    type?: string;
    readonly amount?: number;
};

export namespace Discount_jsonld_promotion_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

