/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Addon_jsonld_cruise_read } from './Addon_jsonld_cruise_read';
import type { BoatPass_jsonld_cruise_read } from './BoatPass_jsonld_cruise_read';
import type { Insurance_jsonld_cruise_read } from './Insurance_jsonld_cruise_read';
import type { Payment_jsonld_cruise_read } from './Payment_jsonld_cruise_read';
import type { ReservationAddress_jsonld_cruise_read } from './ReservationAddress_jsonld_cruise_read';
import type { ReservationPromocode_jsonld_cruise_read } from './ReservationPromocode_jsonld_cruise_read';
import type { ReservationReceipt_jsonld_cruise_read } from './ReservationReceipt_jsonld_cruise_read';
import type { SecurityDeposit_jsonld_cruise_read } from './SecurityDeposit_jsonld_cruise_read';

export type Reservation_jsonld_cruise_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Reservation_jsonld_cruise_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    seats?: number | null;
    addons?: Array<Addon_jsonld_cruise_read>;
    hasCancellation?: boolean;
    insurance?: Insurance_jsonld_cruise_read | null;
    promocode?: ReservationPromocode_jsonld_cruise_read | null;
    boatPass?: BoatPass_jsonld_cruise_read | null;
    receipt?: ReservationReceipt_jsonld_cruise_read;
    customerAddress?: ReservationAddress_jsonld_cruise_read;
    notes?: string | null;
    isSuperoverride?: boolean;
    createdAt?: string;
    readonly updatedAt?: string | null;
    securityDeposit?: SecurityDeposit_jsonld_cruise_read | null;
    readonly isWalkIn?: boolean;
    hasEmailNewsletter?: boolean;
    readonly status?: Reservation_jsonld_cruise_read.status;
    readonly creationSide?: string;
    readonly lastPayment?: Payment_jsonld_cruise_read | null;
    readonly canEdit?: boolean;
    readonly canCancel?: boolean;
};

export namespace Reservation_jsonld_cruise_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum status {
        PROCESSING = 'processing',
        PAID = 'paid',
        CANCELED = 'canceled',
        FAILED = 'failed',
    }


}

