/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Location_jsonld_cruise_read } from './Location_jsonld_cruise_read';

export type BoatGroup_jsonld_cruise_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: BoatGroup_jsonld_cruise_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    location?: Location_jsonld_cruise_read;
    capacity?: number;
    color?: string | null;
};

export namespace BoatGroup_jsonld_cruise_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

