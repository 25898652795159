/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Statistic_IncomeStatisticItem_statistics_read } from '../models/Statistic_IncomeStatisticItem_statistics_read';
import type { Statistic_StatisticSummary_statistics_read } from '../models/Statistic_StatisticSummary_statistics_read';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticService {

    /**
     * Retrieves the collection of Statistic resources.
     * Retrieves the collection of Statistic resources.
     * @returns Statistic_IncomeStatisticItem_statistics_read Statistic collection
     * @throws ApiError
     */
    public static apiAdminstatisticsincomeGetCollection({
        json,
    }: {
        /**
         * Income Statistic Query Json
         */
        json: string,
    }): CancelablePromise<Array<Statistic_IncomeStatisticItem_statistics_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/statistics/income',
            query: {
                'json': json,
            },
        });
    }

    /**
     * Retrieves a Statistic resource.
     * Retrieves a Statistic resource.
     * @returns Statistic_StatisticSummary_statistics_read Statistic resource
     * @throws ApiError
     */
    public static apiAdminstatisticssummaryGet({
        json,
    }: {
        /**
         * Statistic Summary Query Json
         */
        json: string,
    }): CancelablePromise<Statistic_StatisticSummary_statistics_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/statistics/summary',
            query: {
                'json': json,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
