/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Credentials } from '../models/Credentials';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminAuthenticationService {

    /**
     * Login to admin app.
     * @returns void
     * @throws ApiError
     */
    public static postAdminLogin({
        requestBody,
    }: {
        requestBody?: Credentials,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Logout of admin app.
     * @returns any Session invalidated. Refresh page.
     * @throws ApiError
     */
    public static postAdminLogout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/logout',
        });
    }

}
