/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Refund_jsonld_cruise_read } from './Refund_jsonld_cruise_read';

export type Payment_jsonld_cruise_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Payment_jsonld_cruise_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    readonly createdAt?: string;
    status?: string;
    amount?: number;
    currency?: string;
    externalIntentId?: string;
    cardNumber?: string | null;
    readonly error?: string | null;
    readonly productType?: string;
    readonly productId?: string;
    customerId?: number;
    refunds?: Array<Refund_jsonld_cruise_read>;
    readonly cardBrandName?: string | null;
};

export namespace Payment_jsonld_cruise_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

