/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type User_jsonld_user_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: User_jsonld_user_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    email?: string;
    name?: string | null;
    phone?: string | null;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    readonly permissions?: Array<string>;
};

export namespace User_jsonld_user_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

