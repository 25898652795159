/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OverrideCustomDate_override_read } from './OverrideCustomDate_override_read';
import type { OverrideMonthDays_override_read } from './OverrideMonthDays_override_read';
import type { OverrideWeekDay_override_read } from './OverrideWeekDay_override_read';
import type { OverrideYearDates_override_read } from './OverrideYearDates_override_read';

export type Override_override_read = {
    readonly id?: number;
    name?: string;
    boats?: Array<string>;
    startAt?: string | null;
    endAt?: string | null;
    yearDates?: OverrideYearDates_override_read;
    monthDays?: OverrideMonthDays_override_read;
    weekDays?: Array<OverrideWeekDay_override_read>;
    customDates?: Array<OverrideCustomDate_override_read>;
    timeFrom?: string | null;
    timeTo?: string | null;
    readonly createdAt?: string;
    readonly updatedAt?: string | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    readonly frequency?: Override_override_read.frequency;
};

export namespace Override_override_read {

    export enum frequency {
        YEARLY = 'yearly',
        MONTHLY = 'monthly',
        WEEKLY = 'weekly',
        CUSTOM_DATES = 'custom_dates',
    }


}

