/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Address_jsonld_location_collection_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Address_jsonld_location_collection_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    line1?: string;
    line2?: string | null;
    city?: string;
    state?: string;
    zip?: string;
};

export namespace Address_jsonld_location_collection_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

