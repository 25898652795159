/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PromotionTypeDto_jsonld_promotion_write = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: PromotionTypeDto_jsonld_promotion_write.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    usage: PromotionTypeDto_jsonld_promotion_write.usage | null;
    discountAmount: number | null;
    discountType: PromotionTypeDto_jsonld_promotion_write.discountType | null;
};

export namespace PromotionTypeDto_jsonld_promotion_write {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }

    export enum usage {
        SINGLE = 'single',
        SINGLE_PER_USER = 'single_per_user',
        MULTIPLE = 'multiple',
    }

    export enum discountType {
        DOLLAR = 'dollar',
        PERCENT = 'percent',
    }


}

