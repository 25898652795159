/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReservationPromocodeValidationQuery_reservation_promocode_validation_write = {
    promocode: string | null;
    email: string | null;
    excludedReservationId?: number | null;
    startAt: string | null;
    endAt: string | null;
    boatGroup: string | null;
    cruiseType: ReservationPromocodeValidationQuery_reservation_promocode_validation_write.cruiseType | null;
};

export namespace ReservationPromocodeValidationQuery_reservation_promocode_validation_write {

    export enum cruiseType {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

