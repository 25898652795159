/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Info_jsonld_location_collection_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Info_jsonld_location_collection_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    howToFind?: string | null;
    parking?: string | null;
};

export namespace Info_jsonld_location_collection_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

