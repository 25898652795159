/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingFee_jsonld_settings_read } from './BookingFee_jsonld_settings_read';
import type { CruiseLength_jsonld_settings_read } from './CruiseLength_jsonld_settings_read';

export type Settings_jsonld_settings_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Settings_jsonld_settings_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    boatOffset?: number;
    bookingFee?: BookingFee_jsonld_settings_read;
    tipPercent?: number;
    tipStep?: number;
    tipMax?: number;
    readonly securityDeposit?: number;
    securityDepositLockTime?: number;
    securityDepositUnlockTime?: number;
    emailBeforeCruiseTime?: number;
    leaveReviewEmailAfterCruiseTime?: number;
    smsBeforeCruiseTime?: number;
    smsAfterCruiseTime?: number;
    cruiseLengths?: Array<CruiseLength_jsonld_settings_read>;
    isPublicAvailable?: boolean;
    isPrivateAvailable?: boolean;
    stripeApiPublishableKey?: string;
};

export namespace Settings_jsonld_settings_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

